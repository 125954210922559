import React from "react";

export interface NavActionProps {
  click: boolean;
  order: number;
  action: (order: number) => void;
}


export default function TopNav(props: NavActionProps) {
  return (
    <div className="top-nav">
      <div className="logo hand-cursor" onClick={() => { window.location.href = '/'; }}><img alt='logo' src='/images/logo.svg' /></div>
      <div className="learn-more hand-cursor"><h4>Learn Everything About Us !</h4></div>
      <div className="nav-action">
        <div className="contact-us hand-cursor" onClick={() => props.action(8)}><h4>Contact Us</h4></div>
        <div className="our-launch hand-cursor" onClick={() => props.action(7)}><h4>Summit</h4></div>
      </div>
    </div>
  );
}

export function TopNavNoAction() {
  return (
    <div className="top-nav">
      <div className="logo hand-cursor" onClick={() => { window.location.href = '/'; }}><img alt='logo' src='/images/logo.svg' /></div>
    </div>
  );
}