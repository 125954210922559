import React from "react";

export default function JoinMint() {

  const openEmail = () => {
    window.open("mailto:ceo@themint.space");
  }

  return (
    <div className="join-mint-card-holder">
      <div className="join-mint-card-content">
        <div><h2 className="clash-display">Join us!</h2></div>
        <div><p>MInT is guaranteed to provide value, whatever the mobility space you operate in.</p></div>
      </div>
      <div className="join-mind-card-grid">
        <div className="join-mind-card-grid-1">
          <img alt='join-grid-1' src="/images/joinus.png" width={'auto'} height={'280px'} />
        </div>
        <div className="join-mind-card-grid-2">
          <div className="card-content-holder">
            <div><h2 className="clash-display">Collaborative research</h2></div>
            <div>
              <p>Access cutting-edge, inter-disciplinary, practical, deployable research</p>
              <p>Shape the development of national and international standards</p>
            </div>
          </div>
        </div>
        <div className="join-mind-card-grid-3">
          <div className="card-content-holder">
            <div><h2 className="clash-display">Synergistic partnerships and networking</h2></div>
            <div>
              <p>Facilitate joint projects and share knowledge for accelerated growth</p>
            </div>
          </div>
        </div>
        <div className="join-mind-card-grid-4">
          <div className="card-content-holder">
            <div><h2 className="clash-display">Access to skilled talent</h2></div>
            <div>
              <p>Identify and address skills gaps in the workforce</p>
              <p>Recruit the most up-to-date talent</p>
            </div>
          </div>
        </div>
        <div className="join-mind-card-grid-5">
          <div className="card-content-holder">
            <div><h2 className="clash-display">Demonstrable social impact</h2></div>
            <div>
              <p>Accelerate the path to a sustainable, equitable and efficient planet</p>
              <p>Foster the development of indigenous technologies and tools</p>
            </div>
          </div>
        </div>
        <div className="join-mind-card-grid-6 hand-cursor">
          <div className="card-content-holder" onClick={() => openEmail()}>
            <div><h2 className="clash-display">Request to Register</h2></div>
            <img className="open-action" alt="open" src="/images/open.svg" onClick={() => openEmail()} />
          </div>
        </div>
      </div>
    </div>
  );
}