import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';
import "@fontsource/inter";
import "@fontsource/courgette";

import Home from './pages/Home';
import MorePage from './pages/MorePage';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);


//App Main Page Loading.
function App() {
  return (
    <div className="mint-app">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/more' element={<MorePage />} />
      </Routes>
    </div>
  );
}