import React from "react";

interface Tile {
  order: number;
  image: boolean;
  name: string;
  action: (order: number) => void;
}

export default function BentoTile(props: React.PropsWithChildren<Tile>) {
  return (
    <div className={"bento-tile-" + props.order + " " + props.name + (!(props.order === 2 || props.order === 3) ? " hand-cursor" : "")} onClick={() => props.action(props.order)}>
      <div className={(props.order === 2 || props.order === 3 || props.order === 8) ? "card-content-center" : "card-content"}>
        {props.children}
        {props.image && <img className="open-action" alt="open" src="/images/open.svg" onClick={() => props.action(props.order)} />}
      </div>
    </div>
  );
}