import React, { useEffect, useRef } from "react";
import { MorePageProps } from "../pages/MorePage";

export default function HowMint(props: MorePageProps) {

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.scrollTo) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [ref, props.scrollTo]);

  return (
    <div className="how-mint-card-holder" key={'how'} ref={ref}>
      <div className="car-img"><img alt="cars" src="/images/cars.svg" /></div>
      <div className="how-mint-card-content">
        <div><div className="summit black-font border-1 how-back"><b>How</b></div></div>
        <div><h2 className="clash-display">BM<sup>3</sup>S - <br />The Digital Stack</h2></div>
        <div><p>The <b>Bharat Multi-Modal Mobility Stack</b> is a digital platform to foster the <i><b>“Build for India, Solve for the World”</b></i> mantra. It defines mobility as a digital public good, and serves as a benchmark for standardizing, developing and deploying mobility tools and services for everyone.</p></div>
        <div><p>BM<sup>3</sup>S is designed to encompass all dimensions of mobility at the grassroots level covering the following broad areas:</p></div>
      </div>

      <div className="how-grid">
        <div className="how-grid-1">
          <div className="how-card">
            <div><h2 className="caurgette">Advanced vehicle technologies</h2></div>
          </div>
        </div>
        <div className="how-grid-2">
          <div className="how-card">
            <div><h2 className="caurgette">Multi-modal connectivity</h2></div>
          </div>
        </div>
        <div className="how-grid-3">
          <div className="how-card">
            <div><img alt='how-img1' src="/images/how_img1.svg" width={"100%"} height={"auto"} /></div>
          </div>
        </div>
        <div className="how-grid-4">
          <div className="how-card">
            <div><img alt='how-img2' src="/images/how_img2.svg" width={"100%"} height={"auto"} /></div>
          </div>
        </div>
        <div className="how-grid-5">
          <div className="how-card">
            <div><h2 className="caurgette">Standards and infrastructure development</h2></div>
          </div>
        </div>
        <div className="how-grid-6">
          <div className="how-card">
            <div><h2 className="caurgette">Enhanced user experience</h2></div>
          </div>
        </div>
      </div>

      <div className="how-mint-card-content">
        <div><p>Tools and solutions will address one or more of several key components of sustainable mobility for now and for the future. BM<sup>3</sup>S will help with advancing access to goods, services and opportunities while retaining focus on core fundamentals such as equity, climate change, sustainable technologies, safety and standards.</p></div>
        <div><p>The BM<sup>3</sup>S framework is conceptualized as a collaborative repository with continuously evolving components representing all moving parts of the mobility sphere. It includes data, automatic data processing/cleaning, mobility models, and their interactions via a digital twin. It is envisioned that stakeholders may use the stack as a whole, or in part, depending on their specific needs. Partners may use the digital infrastructure, contribute back to it, or both.</p></div>
      </div>

      <div className="how-mint-img">
        <img className="how-mint-img" alt="how-mint" src="/images/how_mint.svg" />
      </div>

      <div className="how-mint-card-content">
        <div><p>BM<sup>3</sup>S may be leveraged by stakeholders in very different ways, depending on their specific needs. A vehicle technology OEM, for example, would be able to review battery technology knowhow from the stack and adopt/modify the same for their own niche use case. A government agency might alternatively run digital twin simulations for “what if” analyses to assess future outcomes of policy options, thus making informed choices today to ensure the best equity and efficiency outcomes tomorrow. Academic and industry users might both be interested in the core algorithms pertaining to data collection techniques, data streams, and processing them into formats that can be input to digital twins or inform further analysis. Mobility service app developers could use algorithmic modules in the stack to jump-start their development and deployment rather than re-invent the wheel. These are just some of the use cases envisioned for the stack, with an overarching umbrella of developing and continuously evolving standards to ensure nationa-wide compatibility, seamless mobility services across geography, and an unprecedented level of service to the end users of all kinds of mobility products and services.</p></div>
      </div>
    </div>
  );
}