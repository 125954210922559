import React from "react";

export default function HomeTitle() {
  return (
    <div className="home-title">
      <div className="home-title-child">
        <img alt="title" src="/images/title.svg" />
      </div>
    </div>
  );
}