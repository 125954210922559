import React, { useEffect, useRef } from "react";
import { MorePageProps } from "../pages/MorePage";

export default function WhatMint(props: MorePageProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.scrollTo) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [ref, props.scrollTo]);

  return (
    <div className="what-mint-card-holder" key={'what'} ref={ref}>
      <div className="what-mint-card">
        <div className="big-logo"><img alt="big-logo" src="/images/big_logo.svg" /></div>
        <div className="what-mint-card-content">
          <div className="what-mint-card-border"></div>
          <div className="what-mint-card-content-text">The <span className="logo-color-1">M</span>obility and <span className="logo-color-2">In</span>telligent <span className="logo-color-3">T</span>ransportation Collaborative</div>
        </div>
      </div>
      <div className="what-mint-card-descrip">
        <p>MInT is a  global initiative to build <b>mobility digital infrastructure</b>, an <b>R&D portfolio</b> and a <b>skilled workforce</b> to develop breakthrough mobility solutions to realize  Sustainable Development Goals (SDG’s), such as:</p>
      </div>
      <div className="what-mind-card-grid">
        <div className="what-mind-card-grid-1">
          <div className="card-content-holder">
            <div><h2 className="clash-display">50%</h2></div>
            <div><p>Reduction  in total transport emissions</p></div>
            <div><div className="summit black-font border-5 ext-width"><b>Sustainable Transport</b></div></div>
          </div>
        </div>
        <div className="what-mind-card-grid-2">
          <div className="card-content-holder">
            <div><h2 className="clash-display">50%</h2></div>
            <div><p>Reduction in fatalities</p></div>
            <div><div className="summit black-font border-6 ext-width"><b>Accident Prevention</b></div></div>
          </div>
        </div>
        <div className="what-mind-card-grid-3">
          <div className="card-content-holder">
            <div><h2 className="clash-display">50%</h2></div>
            <div><p>of all transport from net zero modes</p></div>
            <div><div className="summit black-font border-4 ext-width"><b>Energy Efficiency</b></div></div>
          </div>
        </div>
      </div>
    </div>
  );
}