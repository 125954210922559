import React, { useEffect, useRef } from "react";
import { MorePageProps } from "../pages/MorePage";

export default function WhoMint(props: MorePageProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.scrollTo) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [ref, props.scrollTo]);

  return (
    <div className="who-mint-card-holder" key={'who'} ref={ref}>
      <div className="who-mint-card-content">
        <div><div className="summit black-font border-1 how-back"><b>Who</b></div></div>
        <div><h2 className="clash-display">The Collaborators</h2></div>
        <div><p>IIT Madras anchors the MInT Collaborative with its world-leading technological and human resources in numerous fields. As the institute continues to expand into spaces such as Data Science, Artificial Intelligence, and Neuroscience, it is perfectly positioned to synergize cross-connections between seemingly disparate technological domains for the greater, common good. Mobility is a key example where a meeting of diverse minds is essential to finding holistic solutions that enhance positive outcomes while minimizing or even eliminating possible negative externalities.</p></div>
        <div><p>MInT leverages the ongoing collaborations fostered by IIT Madras with industry partners and researchers to ensure that the long-term vision and agenda are populated with the most pressing problems, and that the solutions build on the cutting edge (both theoretical and deployed) of mobility services offerings.</p></div>
      </div>
      <div className="who-mint-card-content">
        <div><img alt='who-main' src="/images/who-main.png" width={'100%'} height={'auto'} /></div>
      </div>
      <div className="who-mint-card-content">
        <div><p>IIT Madras has been at the core of mobility research with various centres working on advanced automotive design, battery engineering and electric vehicles, wireless technology, railways, urban transport, tyre and vehicle mechanics, ports, waterways and coasts.</p></div>
        <div><p>The various projects at IIT Madras include real-time traffic data, advanced traveler information systems (ATIS), dynamic bus arrival predictions, bus signal priority systems, a personalised transit route guidance app, an intermediate public transport mobility as a service app, dynamic allocation of emergency vehicles, an urban freight transport consolidation app, driving simulators and connected instrumented vehicles.</p></div>
        <div><p>The institute’s continued focus on education is also perfect for the scalable deployment of advanced mobility solutions, through a range of current and future partnerships to ensure that the on-the-ground needs of the ecosystem are met through up-skilling and certification processes.</p></div>
      </div>
      <div className="who-mind-card-grid">
        <div className="who-mind-card-grid-1">
          <img alt='who-grid-1' src="/images/who-grid-1.png" width={'100%'} height={'auto'} />
        </div>
        <div className="who-mind-card-grid-2">
          <img alt='who-grid-2' src="/images/who-grid-2.png" width={'100%'} height={'auto'} />
        </div>
        <div className="who-mind-card-grid-3">
          <img alt='who-grid-3' src="/images/who-grid-3.png" width={'100%'} height={'auto'} />
        </div>
      </div>
    </div>
  );
}