import React, { useState } from "react";
import TopNav from "../components/Nav";
import HomeTitle from "../components/HomeTitle";
import BentoGrid from "../components/BentoGrid";

export default function Home() {

  const [state, setState] = useState({ click: false, order: 0 });

  const action = (order: number) => {
    if (order === 1) {
      window.location.href = '/more?page=what';
    }
    if (order === 4) {
      window.location.href = '/more?page=how';
    }
    if (order === 5) {
      window.location.href = '/more?page=who';
    }
    if (order === 6) {
      window.location.href = '/more?page=why';
    }
    if (order === 7 || order === 8) {
      const scrollingElement = (document.scrollingElement || document.body);
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
      setState({ click: !state.click, order: order });
    }
  };

  return (
    <div className="home">
      <TopNav {...state} action={action} />
      <HomeTitle />
      <BentoGrid {...state} action={action} />
    </div>
  );
}