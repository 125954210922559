import React, { useEffect, useRef } from "react";
import { MorePageProps } from "../pages/MorePage";

export default function WhyMint(props: MorePageProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.scrollTo) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [ref, props.scrollTo]);

  return (
    <div className="why-mint-card-holder" key={'why'} ref={ref}>
      <div className="why-mint-card-content">
        <div><div className="summit black-font border-6 why-back"><b>Why</b></div></div>
        <div><h2 className="clash-display">Mission & Statement</h2></div>
        <div><p>Mobility is ubiquitous in life and is essential for the movement of not only people to enable them to accomplish their desired activities but also goods to ensure the smooth running of the economy. As such, mobility has become the backbone of the modern economy.  The future of mobility is fast transforming due to remarkable advances in technology across several areas driving acceleration of electric vehicles, autonomous driving and intelligent transportation systems.</p></div>
        <div><p>Digital infrastructure, which complements and optimizes the physical infrastructure, will be the innovation engine to advance future mobility. Digital infrastructure connects all aspects of the mobility ecosystem, including data streams, payment mechanisms, vehicle technology stacks, multi-modal passenger and freight networks, and digital twin models. Such a framework is critical for any nation to move forward with its mobility growth in a safe, sustainable and equitable manner. This would be a digital public good.</p></div>
        <div><p>Mobility involves supply-demand interactions across a variety of stakeholders. Allowing for research in silos will lead to sub-optimal solutions that do not exploit the numerous opportunities for synergies and knowledge sharing. We are at an important crossroad, a once in a century opportunity, where digital technology, particularly AI, is redefining every sphere of our life.</p></div>
        <div><p>The MInT collaborative is based on the principle of <i><b>“collaborate and compete”</b></i> as we bring industry competitors, government agencies and academic innovators to the same table and co-create the mobility digital infrastructure (Bharat Multi-Modal Mobility Stack, BM<sup>3</sup>S) to enable holistic system solutions.</p></div>
      </div>
    </div>
  );
}