import React from "react";

import { TopNavNoAction } from "../components/Nav";
import WhatMint from "../components/WhatMint";
import WhyMint from "../components/WhyMint";
import HowMint from "../components/HowMint";
import { useLocation } from "react-router-dom";
import WhoMint from "../components/WhoMint";
import JoinMint from "../components/JoinUs";

export interface MorePageProps {
  scrollTo: boolean;
}

export default function MorePage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");

  return (
    <div className="more-page">
      <TopNavNoAction />
      <WhatMint scrollTo={page === 'what'} />
      <WhyMint scrollTo={page === 'why'} />
      <HowMint scrollTo={page === 'how'} />
      <WhoMint scrollTo={page === 'who'} />
      <JoinMint />
    </div>
  );
}