import React, { useEffect, useState } from "react";
import BentoTile from "./BentoTile";
import { NavActionProps } from "./Nav";

export default function BentoGrid(props: NavActionProps) {

  const [index, setIndex] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const delay = 2500;

  useEffect(() => {
    if (timer) { clearTimeout(timer); }
    setTimer(setTimeout(() => setIndex((prevIndex) => prevIndex === 2 ? 0 : prevIndex + 1), delay));
    return () => { if (timer) { clearTimeout(timer); } };
  }, [index]);

  const openEmail = (email: string) => {
    window.open("mailto:" + email);
  }



  return (
    <div className="bento-grid-holder">
      <BentoTile order={1} image={true} action={props.action} name="">
        <div className="card-content-holder">
          <div><div className="summit black-font border-1"><p>What</p></div></div>
          <div><h2 className="clash-display">The Mint</h2></div>
          <div><p>A global initiative to develop breakthrough mobility solutions to realise SDG goals.</p></div>
        </div>
      </BentoTile>
      <BentoTile order={2} image={false} action={props.action} name="">
        <div className="card-content-holder">
          <div className="power-by">
            <div>
              <div className="iit-logo"><img className="iit-logo" alt="iit-logo" src="/images/iit_logo.png" /></div>
            </div>
            <div>
              <div><h3 className="caurgette iit-font">Powered by</h3></div>
              <div><h2 className="clash-display iit-font1">IIT Madras</h2></div>
            </div>
          </div>
        </div>
      </BentoTile>
      <BentoTile order={3} image={false} action={props.action} name="">
        <div className="card-content-holder">
          <div className={"content-slider content-slider-" + (index + 1)}>
            <div className="center-card slider">
              <div><h2 className="clash-display">1.3 Billion</h2></div>
              <div><p>Vehicles in Use</p></div>
            </div>
            <div className="center-card slider">
              <div><h2 className="clash-display">6 Gigatons</h2></div>
              <div><p>CO<sub>2</sub> per year</p></div>
            </div>
            <div className="center-card slider">
              <div><h2 className="clash-display">50 Million</h2></div>
              <div><p>injured in traffic crashes every year</p></div>
            </div>
          </div>
        </div>
      </BentoTile>
      <BentoTile order={4} image={true} action={props.action} name={props.click ? "hidden" : ""}>
        <div className="card-content-holder">
          <div><div className="summit black-font border-4"><p>How</p></div></div>
          <div><h2 className="clash-display">BM<sup>3</sup>S - The Digital Stack</h2></div>
        </div>
      </BentoTile>
      <BentoTile order={5} image={true} action={props.action} name={props.click ? "hidden" : ""}>
        <div className="card-content-holder">
          <div><div className="summit black-font border-5"><p>Who</p></div></div>
          <div><h2 className="clash-display">The Collaborators</h2></div>
        </div>
      </BentoTile>
      <BentoTile order={6} image={true} action={props.action} name="">
        <div className="card-content-holder">
          <div><div className="summit black-font border-6"><p>Why</p></div></div>
          <div><h2 className="clash-display">Mission & Statement</h2></div>
          <div><p>Digital infrastructure, which complements and optimizes the physical infrastructure, will be the innovation engine to advance future mobility.</p></div>
        </div>
      </BentoTile>
      <BentoTile order={7} image={false} action={props.action} name={(props.click) ? ((props.order === 8) ? "hidden" : "active") : ""}>
        {
          (props.click && props.order === 7) ?
            <div className="card-expanded-summit" >
              <div className="mint-summit-holder"><img className="mint-summit" alt='mint' src='/images/mint_summit.png' /></div>
              <div className="mint-summit-content white-font">
                <p>The inaugural summit held on 4th and 5th April 2024 will formally launch the MInT collaborative with Keynotes from leading personalities Dr. Neeraj Mittal (Secretary, DoT), Prof. Chandra Bhat (UT Austin)  and Prof. Hari Balakrishnan (MIT).  The summit will bring together industry, NGOs and academicians into a roundtable discussions on “Mobility Challenges and SDG” and “Digital technologies for Mobility solutions”.</p>
                <p>The summit will conclude with a joint white paper summarizing the key discussions held over the two days, and tease out a roadmap for the Collaborative both for the short and long terms.</p>
              </div>
            </div>
            :
            <div className="card-content-holder">
              <div><div className="summit white-font border-7"><p>Our Launch</p></div></div>
              <div><h2 className="caurgette white-font">The Inaugural Summit</h2></div>
            </div>
        }

      </BentoTile >
      <BentoTile order={8} image={true} action={props.action} name={(props.click) ? ((props.order === 7) ? "hidden" : "active") : ""}>
        {
          (props.click && props.order === 8) ?
            <div className="contact-us-expand" >
              <div><h2 className="caurgette white-font">Reach out to us for more information!</h2></div>
              <div className="contact-holder">
                <div><img className="profile" alt='mint' src='/images/10.png' /></div>
                <div className="contact-content white-font">
                  <p><b>Dr. Ramakrishna Srinivasan</b></p>
                  <p>CEO, MInT</p>
                  <p className='hand-cursor' onClick={() => openEmail('ceo@themint.space')}>ceo@themint.space</p>
                </div>
              </div>
              <div className="contact-holder">
                <div><img className="profile" alt='mint' src='/images/11.png' /></div>
                <div className="contact-content white-font">
                  <p><b>Prof. Gitakrishnan Ramadurai</b></p>
                  <p>Faculty In Charge, MInT</p>
                  <p className='hand-cursor' onClick={() => openEmail('gitakrishnan@civil.iitm.ac.in')}>gitakrishnan@civil.iitm.ac.in</p>
                </div>
              </div>
            </div>
            :
            <div className="card-content-holder">
              <div><h2 className="clash-display white-font">Contact Us</h2></div>
            </div>
        }
      </BentoTile>
    </div >
  );
}

/**
 <div className="center-card">
            <div><h2 className="clash-display">1.3 Billion</h2></div>
            <div><p>Vehicles in Use</p></div>
          </div>
 */